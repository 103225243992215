<template>

  <div>
    <b-card
        no-body
        class="mb-0"
    >
      <b-table
          sticky-header
          noCollapse
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-stockstocktakingitem-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(production_date)="data">
          {{ toDate(data.item.production_date) }}
        </template>

        <template #cell(stockstatus)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.stockstatus)}`"
          >
            {{ getCodeLabel("stock_status", data.item.stockstatus) }}
          </b-badge>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import stockstocktakingitemUseList from './stockstocktakingitemUseList'
import stockstocktakingitemStore from '../stockstocktakingitemStore'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    ss_id: {
      type: Number,
      default: 0
    },
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stockstocktakingitem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('stockstocktakingitem')) store.registerModule('stockstocktakingitem', stockstocktakingitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockstocktakingitem')) store.unregisterModule('stockstocktakingitem')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stockstocktakingitemUseList({
      ss_id: props.ss_id,
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
