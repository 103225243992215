<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search_excel"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">导入</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="盘点差异编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.ssNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"
                    :params="['warehouseName','warehouseId']"
                    :value="condition.warehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="condition.addTime"
                >
                </xy-input-button>
              </b-col>


              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="状态"
                    label-for="stocktaking_status"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('stocktaking_status')"
                      v-model="condition.stocktakingStatus"
                      name="stocktaking_status"
                      class="select-size-sm"
                      placeholder="请选择状态"
                  />
                </b-form-group>
              </b-col>




              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card
            v-show="advanced_search_modal_excel"
        >
          <b-form

          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库"
                    label-for="stocktaking_status"
                    label-size="sm"
                    class="required"
                >
                  <v-select
                      :options="getCodeOptions('warehouse')"
                      v-model="warehouseId"
                      name="stocktaking_status"
                      class="select-size-sm"
                      placeholder="请选择仓库"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权"
                    label-for="stocktaking_status"
                    label-size="sm"
                    class="required"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="purchaseTeamId"
                      name="stocktaking_status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="show"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          [{{ data.item.id }}]
        </template>

        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <stock-stocktaking-item-list :ss_id="row.item.ss_id"></stock-stocktaking-item-list>
        </template>

        <!-- Column: Type -->
        <template #cell(ss_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
                    [#{{ data.item.ss_id }}]
          {{ data.item.ss_no }}
        </template>

        <!-- Column: Type -->
        <template #cell(purchase_team_id)="data">
          {{ getCodeLabel('purchase_team', data.item.purchase_team_id) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(update_time)="data">
          {{ toDate(data.item.update_time) }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stocktaking_status', data.item.status)}`"
          >
            {{ getCodeLabel("stocktaking_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(memo)="data">
          <div>
            {{  isEmpty(data.item.memo)?'':data.item.memo.substring(0, 10) }}
            <feather-icon
                v-if="!isEmpty(data.item.memo)&&data.item.memo.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.memo+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'stock_stocktaking'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="changeStatus(data.item,1,'提交审核')" v-if="data.item.status===0">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,2,'审核通过')" v-if="data.item.status===1&&user.role_id===14">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,3,'驳回')" v-if="data.item.status===1&&user.role_id===14">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

            <b-dropdown-item @click="del(data.item)" v-if="data.item.status===3||data.item.status===0">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="modal-remark"
        ok-only
        ok-title="确认"
        @ok="saveRemark"
        cancel-title="取消"
        centered
        size="lg"
        title="修改备注"
        ref="remarkModal"
    >
      <b-card :header="`编号：${stockStocktaking.ss_no}`">
        <b-form-textarea
            id="textarea"
            v-model="remarkInfo"
            rows="3"
            max-rows="6"
        />
      </b-card>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty} from '@core/utils/filter'
import stockstocktakingUseList from './stockstocktakingUseList'
import stockstocktakingStore from './stockstocktakingStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {useToast} from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import StockStocktakingItemList from "@/views/apps/stockstocktakingitem/modal/StockStocktakingItemList";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    StockStocktakingItemList,
    AttachmentUpload,
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      advanced_search_modal: false,
      table: [],
      advanced_search_modal_excel: false,
      show: true,
      excelSqlId: '',
      warehouseId:null,
      purchaseTeamId:null,
      stockStocktaking:{},
      remarkInfo:'',
      user:{},
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stockstocktaking/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData();
    },
    resetCondition() {
      this.condition = {}
      this.refetchData()
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockstocktaking')) store.registerModule('stockstocktaking', stockstocktakingStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockstocktaking')) store.unregisterModule('stockstocktaking')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const searchByConditionExcel = function () {
      this.show = false;

      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      if (this.warehouseId == null){
        toast.error("请选择仓库")
        this.show = true
        return false
      }
      if (this.purchaseTeamId == null){
        toast.error("请选择销售权")
        this.show = true
        return false
      }
      const params = {
        excelSqlId: this.excelSqlId,
        warehouseId:this.warehouseId.value,
        ptId:this.purchaseTeamId.value,
      }

      store.dispatch('stockstocktaking/exportExcel', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              refetchData()
              this.warehouseId = null
              this.purchaseTeamId = null
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        if (status === 3){
          this.stockStocktaking = params
          this.$refs['remarkModal'].show()
        }else {
          store.dispatch('stockstocktaking/changeStatus', {id: params.ss_id, status: status}).then(res => {
            if (res.data.code == 0) {
              toast.success("操作成功")
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      }
    }

    const del = function (params) {
      if (confirm("是否删除？")){
        store.dispatch('stockstocktaking/del', {id: params.ss_id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const saveRemark = function () {
      store.dispatch('stockstocktaking/saveRemark', {
        id: this.stockStocktaking.ss_id,
        remark: this.remarkInfo
      }).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
          this.remarkInfo = null
          store.dispatch('stockstocktaking/changeStatus', {id: this.stockStocktaking.ss_id, status: 3}).then(res => {
            if (res.data.code == 0) {
              toast.success("操作成功")
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        } else {
          toast.error("修改失败")
        }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = stockstocktakingUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,

      advanced_search,
      onUploaded,
      searchByConditionExcel,
      advanced_search_excel,
      fromChildren,
      changeStatus,
      del,
      saveRemark,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
